@import '../variables';

.renewal-reminder {
  background-color: $orange;
  padding: 12px 36px;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  color: #fff;
  column-gap: 8px;
  align-items: center;
  // margin-top: 72px;
  user-select: none;
  &--link {
    color: #fff;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
  }
  &--close {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.renenwal-reminder-dialog {
  &--image {
    padding: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 48px !important;
      margin: 0 auto !important;
    }
  }
  &--content {
    > p {
      text-align: center;
      color: #313131 !important;
      font-weight: 400 !important;
    }
  }
  &--buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content max-content;
    row-gap: 12px;
    margin-top: 24px;
  }

}

.esim-to-wcb {
  color: #0097a9;
  text-decoration: underline;
}