@import '../../sass/variables.scss';

.app-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 1;
  animation: showSlow 0.3s ease-in-out forwards;
}

@keyframes showSlow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app-modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  padding: 32px 36px;
  max-width: 400px;
  border-radius: 28px;
  box-sizing: border-box !important;
}

.app-modal-content-card {
  background: #fff;
  border-radius: 16px;
  padding: 24px 32px;
}

.app-modal-content-card--signup {
  background: #fff;
  border-radius: 16px;
  padding: 24px 24px;
}

.app-modal-close {
  position: absolute;
  top: 24px;
  right: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #ffffff;
}

.app-modal-close:hover {
  color: #ff0000;
}

.app-modal-content-body {
}


.app-modal-actions-buttons {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-end;
  column-gap: 12px;
  margin: 24px 0 18px 0;
}

