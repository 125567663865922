@import '../variables';

.closed-styles.app-accordion {
  border-bottom: 1.5px solid #e3dede;
  padding-bottom: 18px;
}

.closed-styles {
  .app-accordion--bar--description {
    font-size: 20px !important;
    color: #979797;
    font-weight: 500;
  }
  .app-accordion--bar--number {
    font-size: 14px !important;
    width: 18px !important;
    height: 18px !important;
    opacity: 0.5;
  }
}

.app-accordion {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  &--bar {
    cursor: pointer;
    padding: 12px;
    // background: $soft;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
    user-select: none;
    &--number {
      color: $zblack;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1.5px solid $zblack;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
    }
    &--description {
      color: $zblack;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      padding: 0 16px;
    }
  }
  &--content-wrapper {
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-in-out;
    transition: 0;
  }
  &--content {
    padding: 24px 0 0 50px;
    transform-origin: top;
  }
  &--content-special {
    padding: 24px 0 0 36px;
    transform-origin: top;
  }
}

.addOpen {
  animation: openAccordion 0.3s ease-in-out forwards;
}


.app-accordion.open {
  height: auto;

}

.app-accordion--content-wrapper--open {
  height: max-content;
  animation: openAccordion 1 ease-in-out forwards;
}


@keyframes  openAccordion {
  0% {
    height: 0;
  }
  100% {
    height: auto
  }
}

@media (max-width: 768px) {
  .app-accordion {
    &--bar {
      padding: 12px 0;
      &--description {
        font-size: 22px !important;
      }
    }
    &--content {
      padding: 0 !important;
    }
    &--content-special {
      padding: 12px 0 !important;
    }
  }
}